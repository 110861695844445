import React, { Component } from 'react';
import './Footer.css'

class Footer extends Component {
    render() {
        return (
            <div>
                <footer className="footer-section">
                    <div className="container">

                        <ul className="main-menu footer-menu">
                            <li><a href="/">HOME</a></li>
                            <li><a href="/whatwedo">WHAT WE DO</a></li>
                            <li><a href="/work">WORK</a></li>
                            <li><a href="/products">PRODUCTS</a></li>
                            <li><a href="/contact">CONTACT</a></li>
                        </ul>

                        <div className="footer-social d-flex justify-content-center">
                            <a href="#"><i className="fa fa-pinterest"></i></a>
                            <a href="#"><i className="fa fa-facebook"></i></a>
                            <a href="#"><i className="fa fa-twitter"></i></a>
                            <a href="#"><i className="fa fa-dribbble"></i></a>
                            <a href="#"><i className="fa fa-behance"></i></a>
                        </div>
                        <div className="copyright">Copyright © 2019 Siqalo Digital, website developed by <a href="http://adrodmedia.co.za/" target="_blank">Ad-Rod Media</a></div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;