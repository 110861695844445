import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import Product from './components/Products';
import Whatwedo from './components/Whatwedo';
import Work from './components/Work';
import Products from './components/Products';
import Promo from './pages/Promo';



function App() {
  return (
    <Router>
      <div>
      <Route exact path="/" component={Home} />
      <Route exact path="/Whatwedo" component={Whatwedo} />
      <Route exact path="/Work" component={Work} />
      <Route exact path="/products" component={Products} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/promo" component={Promo} />
     
        {/* <Footer /> */}
       
      </div>
    </Router>


  );
}

export default App;
