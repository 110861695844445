import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Button, Jumbotron} from 'react-bootstrap';
import './Home.css';

class Home extends Component {
    render() {
        return (
            <div>
                <Container>
                    <section>
                        <div className="row">

                        <h1>Work</h1>
                        <img src="assets/images/projects/01_projects.jpg" alt="" />

                        </div>
                    </section>


{/*                     
                <section>

			<div class="row">
				<div class="col-lg-12">
					<h1>work</h1>
				</div>
				<div class="col">
					<img src="images/projects/01_projects.jpg" alt="" />
					<br>
					<br>
					<br>
				</div>



			
		</div>
	</section> */}
                </Container>
            </div>
        );
    }
}

export default Home;