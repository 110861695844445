import React, { useState } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Promo.css';
import { Redirect } from "react-router";

const API_PATH = "http://delta.siqalodigital.co.za/services/sendmail.php";
toast.configure()

const Promo = () => {
  

  const redirect = () => {
    setTimeout(() => {
      console.log("it worked");
      window.location.href = "http://siqalodigital.co.za/whatwedo.html";
    }, 5000);


    
}


  const [state, setState] = useState({ mailSent: false, error: null, isDisabled: false })
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = (data) => {
        console.log(data);

        axios({
            method: "post",
            url: `${API_PATH}`,
            headers: { "content-type": "application/json" },
            data: data,
        })
            .then((result) => {
                console.log(result)
                setState({
                    mailSent: result.data.sent,
                    error: null,
                    isDisabled: true,
                });
                notify();
                redirect();
            })
            .catch((error) => setState({ error: error.message, mailSent: false }));
        console.log(
            "that is the messages am sending that i cant see",
            //   this.props.mailSent
        ); 

        /* for testing only
        notify();
        redirect(); */
    }
   

    const notify = () => {
        toast.success('Your form has been sent successful!')
    }


  return (
    <div>
      <div>
        <img src="assets/images/billboard_promo.jpg" alt="" width="100%" />
        <br /><br />
      </div>
      <Container>
        <Row>
          <Col lg="3">
            <h1>Drop us a line</h1>
            <p>
              Fill in this form or send us an <br /> 
              <a href="mailto:info@siqalodigital.co.za">e-mail </a>with your inquiry.
            </p>
          </Col>
          <Col lg="9">
            <Form
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)} 
            >
              <Form.Row>
                <Form.Group as={Col} controlId="fullname">
                  <Form.Label>Name (required)</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    name="fullname"
                    placeholder="Fullname"
                    ref={register({required: "Full Name is required"})}
                  />
                  {errors.fullname && 
                   <Form.Text className="text-muted text-custom">
                   {errors.fullname.message}
                 </Form.Text>
                 }
                </Form.Group>

                <Form.Group as={Col} controlId="company">
                  <Form.Label>Company (required)</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    name="company"
                    placeholder="Company"
                    ref={register({required: "Company Name is required"})}
                  />
                    {errors.company && 
                   <Form.Text className="text-muted text-custom">
                   {errors.company.message}
                 </Form.Text>
                 }
                </Form.Group>

                <Form.Group as={Col} controlId="email">
                  <Form.Label>Email (required)</Form.Label>
                  <Form.Control
                    size="lg"
                    type="email"
                    name="email"
                    placeholder="Email"
                    ref={register ({
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Enter a valid e-mail address",
                      },
                    })}
                  />
                    {errors.email && 
                   <Form.Text className="text-muted text-custom">
                   {errors.email.message}
                 </Form.Text>
                 }
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="telephone">
                  <Form.Label>Telephone (optional)</Form.Label>
                  <Form.Control
                    size="lg"
                    type="tel"
                    name="telephone"
                    placeholder="Telephone"
                    ref={register ({
                      pattern: {
                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                        message: "Enter a valid telephone number",
                      },
                     })}
                  />
                    {errors.telephone && 
                   <Form.Text className="text-muted text-custom">
                   {errors.telephone.message}
                 </Form.Text>
                 }
                </Form.Group>

                <Form.Group as={Col} controlId="mobile">
                  <Form.Label>Mobile Number (optional)</Form.Label>
                  <Form.Control
                    size="lg"
                    type="tel"
                    name="mobile"
                    placeholder="Mobile"
                    ref={register ({
                      pattern: {
                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                        message: "Enter a valid mobile number",
                      },
                     })}
                  />
                    {errors.mobile && 
                   <Form.Text className="text-muted text-custom">
                   {errors.mobile.message}
                 </Form.Text>
                 }
                </Form.Group>

                <Form.Group as={Col} controlId="duration">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control size="lg" as="select" name="duration" ref={register}>
                    <option>Select your option</option>
                    <option>1 Month</option>
                    <option>3 Months plus</option>
                    <option>6 Months plus</option>
                    <option>12 Months</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Additional Message</Form.Label>
                <Form.Control as="textarea" rows={3} name="message" ref={register}/>
              </Form.Group>
              <Button className="custom_btn" size="lg" type="submit" disabled={state.isDisabled}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <br /> <br />
    </div>
  );
};

export default Promo;
