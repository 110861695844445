import React, { Component } from 'react';

class Whatwedo extends Component {
    render() {
        return (
            <div>
                Whatwedo
            </div>
        );
    }
}

export default Whatwedo;