import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image, Button, Jumbotron} from 'react-bootstrap';
import './Products.css';


class Products extends Component {
    render() {
        return (
            <div>
              

                <div className="contact-page">
                   
              
                <Container>
                    <section>
                    <img src="assets/images/product_video_wall.jpg" alt="" width="100%" />
                <h2>Video Walling</h2>
                <p>The traditional poster has also been given a digital facelift providing a ﬂexible, dynamic and
                    stylish alternative to the printed poster. Video walling is stylish and ﬂexible.</p>
                <p>
                    Video walling have long been a practical and more aesthetically appealing method of advertising.
                    Often combining art, imagery and branding, the posters have been used to provide visual appeal along
                    with traditional advertising. They are also very ﬂexible and can be placed on any wall that has the
                    space.
                </p>
                <p>
                    Video walling enables users to display their messages more dynamically and targeted. In today`s
                    media cluttered environment dynamic images are more appealing – and can convey considerably more
                    information – than static ones can. Using media players video walling can provide changing imagery,
                    including eye-catching moving images.
                </p>
                    </section>

                    <section>
                    <img src="assets/images/product_touch_tables.jpg" alt="" width="100%" />
                <h2>Touch Tables</h2>
                <p>Touch tables are a user-friendly surface computing technology offered in nearly any shape or size to
                    suit any requirement.
                    The turnkey multi-touch screen display, stands at 36” high, with a 30, 40 or 55” diagonal
                    gesture-controlled screen, powerful multi-touch computer, projector and speakers.</p>
                <p>
                    Special effect applications can be included in the multi- touch display table.
                    The touch technology lets people use hand and ﬁnger gestures to interact with multi-media content,
                    access corporate information, play games, create special effects, manipulate art and photographs and
                    even view advertising, in a truly unique and compelling way.
                </p>
                    </section>



                    <section>
                    <img src="assets/images/product_touch_ kiosks.jpg" alt="" width="100%" />
                <h2>Touch Screens</h2>
                <p>Camberleighs is a distributor and manufacturer of touch screens in Africa and the rest of the world.
                    Our touch screens are available on rental and purchase options. We also build touch screens and
                    touch screen kiosks to speciﬁcation.</p>
                <p>
                    Touch screens are becoming increasingly popular worldwide and offer a new easy approach for your
                    client to interactively engage with your brand. Icons and text are big and clearon a touch screen,
                    which makes it physically easier to select the right options. In this way, touch screens ensure that
                    your investment in a top-rated machine pays off, since you’re more likely to use it if it’s easier
                    to use</p>

                <b>Camberleighs Touch Screens are perfect for:</b>
                <ul>
                    <li>Branding</li>
                    <li>Brochure viewing</li>
                    <li>Video playback</li>
                    <li>Website viewing</li>
                    <li>Brand engagement</li>
                    <li>Live interaction throughout your website</li>
                    <li>Navigation through an interactive ﬂash presentation</li>
                    <li>Showing off your product features and speciﬁcations digitally</li>
                    <li>Conversion of a standard banner into a digital interactive video loop</li>
                    <li>Database building</li>
                    <li>Guest sign-in systems</li>
                    <li>Floor plan viewing and navigation screens</li>
                    <li>Public gaming machines</li>
                    <li>Point of sale</li>
                </ul>
                    </section>

                    <section>
                    <img src="assets/images/product_interactive_displays.jpg" alt="" width="100%" />
                <h2>Interactive Displays</h2>
                <p>Interactive displays are perfect for advertising, exhibitions, promotions and entertainment.
                    Content can be controlled from any distance and projected onto any size with no mouse or other
                    devices required.
                </p>
                <p>
                    Interactive displays allow for touch free interactivity that draws crowds, builds product awareness,
                    enhances the customer experience and reinforces a high-tech brand image.</p>

                <img src="assets/images/product_interactive_floor.jpg" alt="" width="100%" />
                <h2>Interactive Floor</h2>
                <p></p>

                <img src="assets/images/product_vr.jpg" alt="" width="100%" />
                <h2>VR</h2>
                <p>We give you a batting Experience in Virtual Reality.</p>

                <p>While Virtual Reality (VR) allows a person to experience a simulated 3D rendering of your custom
                    design in real-time stereoscopic imaging</p>
                    </section>


                </Container>
                </div>

            </div>
                );
            }
        }
        
export default Products;